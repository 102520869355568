<script setup lang="ts">
const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  playOnMount: {
    type: Boolean,
    default: true,
  },
})

const video = ref()
const { playing, currentTime, volume } = useMediaControls(video, {
  src: props.src,
})

// Change initial media properties
onMounted(() => {
  playing.value = props.playOnMount
  volume.value = 0.8
  currentTime.value = 0
})
</script>

<template>
  <video
    ref="video"
    controls
    controlsList="nodownload"
  />
</template>
